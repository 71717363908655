import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CardWithHeaderActions } from '../../../components/layout/CardContent';
import { TransactionTable } from '../../transactions/components/TransactionTable';
import TransactionSpendGraph from '../../../components/charts/TransactionSpendGraph';
import { ChargePointForm } from './ChargePointForm';

import { PagedListContext } from '../../../dataAccess/PagedListContext';

import { MapGraphData } from '../../../utils/MapGraphData';
import { Settings } from '../../../utils/Settings';
import { ConnectorStatusPills } from '../../../utils/ConnectorStatus';
import { ButtonRestart } from '../../../components/ButtonRestart';
import { MapStatic } from '../../../components/maps/MapStatic';

const HeaderActions = ({ isOnline, chargePointId }) => {
	return (
		<div className="d-flex align-items-end">
			<ButtonRestart isOnline={isOnline} chargePointId={chargePointId} />
		</div>
	);
};

const SortBy = ({ sorting, id, propName, sortKeys }) => {
	const [showDropdown, setShowDropdown] = useState(false);
	const [selection, setSelection] = useState(null);
	const { t } = useTranslation();

	const sortBy = [
		{
			label: t('c.connectorAscending'),
			order: 'asc',
			key: sortKeys[0],
		},
		{
			label: t('c.connectorDescending'),
			order: 'desc',
			key: sortKeys[0],
		},
		{
			label: t('c.statusAscending'),
			order: 'asc',
			key: sortKeys[1],
		},
		{
			label: t('c.statusDescending'),
			order: 'desc',
			key: sortKeys[1],
		},
	];

	const DropdownItem = ({ label, sortKey, sortOrder }) => {
		return (
			<button className={`dropdown-item ${selection === label ? 'active' : ''}`} onClick={() => sort(propName, sortKey, sortOrder, label)}>
				{label}
			</button>
		);
	};

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	const sort = (arrayProperty, property, order, sortBy) => {
		toggleDropDown();
		setSelection(sortBy);
		sorting(arrayProperty, property, id, order, sortBy);
	};

	return (
		<div className="d-none d-sm-flex align-items-center d-print-none">
			<div className="dropdown">
				<button className="btn btn-sm btn-outline-secondary mr-2 dropdown-toggle" onClick={toggleDropDown}>
					<i className="fal fa-bars-sort mr-2" />
					{t('c.sortBy')}
				</button>
				<div className={`dropdown-menu dropdown-menu-right ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
					{sortBy.map((item, index) => {
						return <DropdownItem key={index} label={item.label} sortKey={item.key} sortOrder={item.order} />;
					})}
				</div>
			</div>
		</div>
	);
};

export const ChargePointInfo = ({ chargePoint = null, sorting, connectorSorting, sortKeys }) => {
	const { t } = useTranslation();

	const { formatFromNow } = Settings;
	const { recId, isOnline, geolocation = {}, lastContact } = chargePoint;
	const deviceStatus = `${isOnline ? t('g.online') : t('g.offline')} - ${t('g.lastSeen')} ${formatFromNow(lastContact)}`;

	const { latitude = 0, longitude = 0 } = geolocation;
	const [days, setDays] = useState(7);
	const [pageSize, setPageSize] = useState(Settings.pageSize);

	const { data: usageData, getData: getUsage } = PagedListContext(`report/chargeSessionSummary/${days}/chargePoint/${recId}`, null, 0);
	const { data: txnData, getData: getTxnData } = PagedListContext('chargeTransaction', `/chargePointId/${recId}`, pageSize);

	let graphData = {};
	switch (days) {
		case 7: {
			graphData = MapGraphData.toDayOfWeek(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
		case 30: {
			graphData = MapGraphData.toDayOfMonth(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
		case 365: {
			graphData = MapGraphData.toMonthOfYear(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
	}

	const updateFilter = async (graph, days) => {
		setDays(days);
	};

	const loadMore = () => {
		setPageSize(pageSize + Settings.pageSize);
	};

	useEffect(() => {
		getUsage();
		getTxnData();
		sorting(connectorSorting, sortKeys[0], chargePoint.recId, 'asc');
	}, [chargePoint.recId, days, pageSize]);

	return (
		<Fragment>
			<div className="row d-print-none">
				<div className="col-xl-6 mb-3">
					<CardWithHeaderActions
						heading={
							<div className="d-block flex-grow-1">
								<div>{t('cp.chargePointInformation')}</div>
								<div className="text-xs text-gray-500 d-flex">
									<div className="mr-2">{deviceStatus}</div>
									{chargePoint !== null && chargePoint[connectorSorting]?.length <= 3 && (
										<ConnectorStatusPills item={chargePoint} connectorPropName={connectorSorting} indexPropName={sortKeys[0]} showDeviceStatus={false} />
									)}
								</div>
							</div>
						}
						actions={<HeaderActions isOnline={isOnline} chargePointId={chargePoint.recId} />}>
						<div className="row">
							<div className="col-12 col-md-6">
								<ChargePointForm chargePoint={chargePoint} />
							</div>
							<div className="col-12 col-md-6" style={{ minHeight: 200 }}>
								<div className="w-100 h-100">
									<div className="media-map" style={{ maxHeight: 200 }}>
										<MapStatic markerLat={latitude} markerLng={longitude} />
									</div>
								</div>
							</div>
						</div>
					</CardWithHeaderActions>
				</div>
				<div className="col-xl-6 mb-3">
					<TransactionSpendGraph data={graphData} height={214} updateFilter={updateFilter} />
				</div>
			</div>
			{chargePoint !== null && chargePoint[connectorSorting]?.length > 3 && (
				<div className="row">
					<div className="col-xl-12 mb-3">
						<CardWithHeaderActions heading={t('c.connectorStatuses')} actions={<SortBy sorting={sorting} id={chargePoint.recId} propName={connectorSorting} sortKeys={sortKeys} />}>
							<div className="row">
								<div className="col-xl-12">
									<ConnectorStatusPills item={chargePoint} connectorPropName={connectorSorting} indexPropName={sortKeys[0]} showDeviceStatus={false} extraInfo={true} />
								</div>
							</div>
						</CardWithHeaderActions>
					</div>
				</div>
			)}
			<div className="row">
				<div className="col-xl-12 mb-3">
					<TransactionTable
						title={t('g.latestTransactions')}
						isLoading={txnData.isBusy}
						items={txnData.items}
						showLocation={false}
						showCard={false}
						loadMore={loadMore}
						groupBy={['chargePointPhysicalReference', 'chargeTokenDescription']}
					/>
				</div>
			</div>
		</Fragment>
	);
};
