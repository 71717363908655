import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { CardWithHeaderFooterActions } from '../../../components/layout/CardContent';
import { GroupedTable } from '../../../components/Tables';
import { EmptyData } from '../../../components/EmptyData';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';
import { ConfirmModal } from '../../../components/modals/Modals';
import { HttpContext } from '../../../dataAccess/HttpService';

import { Settings } from '../../../utils/Settings';
import { GroupBy } from '../../../utils/GroupByHelper';
import Loading from '../../../components/Loading';
import { downloadCSVFile } from '../../../utils/JsUtils';

const ReimbursementActions = (translate, index = null) => {
	const actions = [
		{ label: translate('r.approve'), value: 'Approved' },
		{ label: translate('r.reject'), value: 'Rejected' },
	];

	if (index != null) return actions[index];
	return actions;
};

const cleanSheetName = (sheetName) => {
	return sheetName.replace(/[/\\?%*:|"<>]/g, '-');
};

const calculateTotal = (list = null, property = null, nestedProp = null) => {
	if (list === null || property === null) return 0;

	let total = 0;
	list.forEach((element) => {
		total += nestedProp ? element[property][nestedProp] : element[property];
	});

	return total;
};

const groupTemplate = (item = null, rest) => {
	if (item === null) return null;

	const getCurrency = () => {
		return item.data[0]?.transactionBilledAmounts?.reportingCurrency || item.data[0]?.transactionBilledAmounts?.currency;
	};

	const { t, showCard = true, showLocation = true, label = '', title = t('t.total'), selectAll = false, isManagePayments = false } = rest;

	const totalDuration = Settings.formatDuration(calculateTotal(item.data, 'transactionDuration') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(item.data, 'transactionEnergyUsed'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(item.data, isManagePayments ? 'transactionBilledAmounts' : 'transactionBilledAmountExcl', isManagePayments ? 'amountExcl' : null), 2);

	return (
		<tr className="text-primary d-none d-md-table-row">
			<td className="p-0 pb-2 pt-2 pr-1"></td>

			{showCard && <td className="p-0 pb-2 pt-2 pr-1"></td>}
			{showLocation && <td className="p-0 pb-2 pt-2 pr-1"></td>}

			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{title}</td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">
				{totalBilled} {getCurrency()}
			</td>
			{selectAll && <td className="p-0 pb-2 pt-2 pr-1"></td>}
		</tr>
	);
};

const tableFooter = (items = null, rest) => {
	if (items === null) return null;

	const { t, showCard = true, showLocation = true, selectAll = false, isManagePayments = false } = rest;

	const totalDuration = Settings.formatDuration(calculateTotal(items, 'transactionDuration') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(items, 'transactionEnergyUsed'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(items, isManagePayments ? 'transactionBilledAmounts' : 'transactionBilledAmountExcl', isManagePayments ? 'amountExcl' : null), 2);

	return (
		<tr className="text-secondary d-none d-md-table-row">
			<td className="p-0 pb-2 pt-2 pr-1"></td>
			{showCard && <td className="p-0 pb-2 pt-2 pr-1"></td>}
			{showLocation && <td className="p-0 pb-2 pt-2 pr-1"></td>}

			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="p-0 pb-2 pt-2 pr-1"></td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{t('t.reportTotal')}</td>

			<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
			<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
			{selectAll && <td className="p-0 pb-2 pt-2 pr-1"></td>}
		</tr>
	);
};

const tableRow = (item, index, rest, selectAll = false, toggleCheckbox = null) => {
	const { t, showCard = true, showLocation = true, swapCardLocation = false } = rest;

	return (
		<Fragment>
			<tr key={`table-row-1-${index}`} className="d-none d-md-table-row" onClick={(event) => toggleCheckbox(event, item)}>
				<td className="p-0 pb-2 pt-2" style={{ width: 5 }}>
					<div className="d-flex align-items-baseline">
						<Link className="d-print-none" to={`/session/${item?.transactionId}`}>
							<i className="fas fa-magnifying-glass" />
						</Link>
						{Settings.emptyDate(item?.transactionTimeEnd || item?.transactionDateStopped) && <i className="fal fa-bolt ml-2 p-0 text-primary" />}
					</div>
				</td>

				{swapCardLocation ? (
					<Fragment>
						{showLocation && (
							<td className="p-1 pb-2 pt-2 pr-1">
								<div className="d-none d-print-block">{item.chargePointLocation}</div>
								<Link className="d-print-none p-0" to={`/chargePoint/${item?.chargePointId}`}>
									{item.chargePointPhysicalReference ?? ''}
								</Link>
							</td>
						)}
						{showCard && (
							<td className="p-1 pb-2 pt-2 pr-1">
								<div className="d-none d-print-block">
									{item.chargeTokenDescription}
									{item.chargeTokenReference ? ' | ' + item.chargeTokenReference : ''}
								</div>
								<Link className="d-print-none  p-0" to={`/chargeCards/${item.chargeTokenId}`}>
									{item.chargeTokenDescription}
									{item.chargeTokenReference ? ' | ' + item.chargeTokenReference : ''}
								</Link>
							</td>
						)}
					</Fragment>
				) : (
					<Fragment>
						{showCard && (
							<td className="p-1 pb-2 pt-2 pr-1">
								<div className="d-none d-print-block">
									{item.chargeTokenDescription}
									{item.chargeTokenReference ? ' | ' + item.chargeTokenReference : ''}
								</div>
								<Link className="d-print-none" to={`/chargeCards/${item.chargeTokenId}`}>
									{item.chargeTokenDescription}
									{item.chargeTokenReference ? ' | ' + item.chargeTokenReference : ''}
								</Link>
							</td>
						)}
						{showLocation && (
							<td className="p-1 pb-2 pt-2 pr-1">
								<div className="d-none d-print-block">{item.chargePointPhysicalReference}</div>
								<Link className="d-print-none" to={`/chargePoint/${item?.chargePointId}`}>{`${item.chargePointLocation ?? ''} ${item.chargePointPhysicalReference ?? ''}`}</Link>
							</td>
						)}
					</Fragment>
				)}

				<td className="text-right p-0 pb-2 pt-2 pr-1 text-lg-left">{Settings.formatDate(item.transactionTimeStart || item.transactionDateStarted, 'date')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{item.transactionConnector || item.transactionConnectorId}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDate(item.transactionTimeStart || item.transactionDateStarted, 'time')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDate(item.transactionTimeEnd || item.transactionDateStopped, 'time')}</td>

				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDuration(item.transactionDuration * 1000, null, 'HH:mm:ss')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatNumber(item.transactionEnergyUsed, 2)}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">
					{Settings.formatNumber(item.transactionBilledAmountExcl || item.transactionBilledAmounts.amountExcl, 2)}{' '}
					{item.transactionBilledAmounts.reportingCurrency || item.transactionBilledAmounts.currency}
				</td>
				{selectAll && toggleCheckbox !== null && (
					<td className="text-center pl-5 pr-1 pb-2 pt-2">
						<input type="checkbox" className="mr-2" checked={item.checked} readOnly={true} />
					</td>
				)}
			</tr>

			<tr key={`table-row-2-${index}`} className="d-table-row d-md-none d-print-none" onClick={(event) => toggleCheckbox(event, item)}>
				<td className="border">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column flex-grow-1">
							<div className="d-flex flex-row flex-grow-1 align-items-center">
								{Settings.emptyDate(item?.transactionTimeEnd || item?.transactionDateStopped) && <i className="fal fa-bolt mr-1 p-0 text-primary" />}
								{showLocation && <div className="mb-1">{`${item.chargePointLocation} ${item.chargePointPhysicalReference}`}</div>}
							</div>

							<div className="mb-1">
								<i className="fal fa-cards-blank mr-1" />
								{item.chargeTokenDescription}
								{item.chargeTokenReference ? ' | ' + item.chargeTokenReference : ''}
							</div>

							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-play ml-1 mr-1" />
									{Settings.formatDate(item.transactionTimeStart || item.transactionDateStarted, 'datetime')}
								</div>
							</div>

							<div className="d-flex">
								<div className="flex-grow-1">
									<i className="fal fa-battery-bolt mr-1" />
									{Settings.formatNumber(item.transactionEnergyUsed, 2)} {t('g.kwh')}
								</div>
								<div className="flex-grow-1 text-right">
									<i className="fal fa-clock mr-1" />
									{Settings.formatDuration(item.transactionDuration, null, 'HH:mm:ss')}
								</div>
							</div>
						</div>
						{selectAll && toggleCheckbox !== null && (
							<div className="text-center p-0 pl-2 pr-2">
								<input type="checkbox" className="mr-2" checked={item.checked} readOnly={true} />
							</div>
						)}
						<div className="ml-3">
							<Link className="d-print-none" to={`/session/${item?.transactionId}`}>
								<i className="fal fa-chevron-right font-weight-bold" />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</Fragment>
	);
};

const HeaderActions = ({ action }) => {
	const { hasData, printForm, downloadFile } = action;
	const [showDropdown, setShowDropdown] = useState(false);
	const { t } = useTranslation();

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	const triggerDownload = (e, format) => {
		e.preventDefault();
		toggleDropDown();
		downloadFile(format);
	};

	return (
		<Fragment>
			<div className="d-none d-sm-flex align-items-center d-print-none">
				<button className="btn btn-sm btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print mr-1" />
					{t('g.print')}
				</button>

				<button className="btn btn-sm btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope mr-1" />
					{t('g.email')}
				</button>

				<div className="dropdown">
					<button className="btn btn-sm btn-outline-secondary mr-2 dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download mr-2" />
						{t('g.download')}
					</button>
					<div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>

			<div className="d-flex d-sm-none">
				<button className="btn btn-icon btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print" />
				</button>
				<button className="btn btn-icon btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope" />
				</button>
				<div className="dropdown">
					<button className="btn btn-icon btn-outline-secondary dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download" />
					</button>
					<div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const FooterActions = ({ action, hasData, calculateTotal = null, translate }) => {
	const triggerReimbursement = (e, format) => {
		action(e, format);
	};

	return (
		<div className="d-none d-sm-flex justify-content-end align-items-center d-print-none ">
			{calculateTotal && (
				<div className="ml-2 mr-3">
					{translate('r.total')}: {Settings.formatNumber(calculateTotal(), 2)}
				</div>
			)}
			<button className="btn btn-sm btn-success ml-2" disabled={!hasData} onClick={(e) => triggerReimbursement(e, ReimbursementActions(translate, 0).value)}>
				<i className="fal fa-check mr-1" />
				{ReimbursementActions(translate, 0).label}
			</button>
			<button className="btn btn-sm btn-danger ml-2" disabled={!hasData} onClick={(e) => triggerReimbursement(e, ReimbursementActions(translate, 1).value)}>
				<i className="fal fa-times mr-1" />
				{ReimbursementActions(translate, 1).label}
			</button>
		</div>
	);
};

const buildHeaders = (t, showCard = false, showLocation = false, swapCardLocation = false, selectAll = false) => {
	let headers = [];
	headers.push({ title: selectAll ? '' : t('g.view'), className: `col-0 d-print-none align-top p-0 ${selectAll ? 'pl-4 pr-4' : 'pr-1'}` });

	if (swapCardLocation) {
		if (showLocation) headers.push({ title: t('t.chargePoint'), className: 'align-top p-0 pr-1' });
		if (showCard) headers.push({ title: t('t.chargeCardUsed'), className: 'align-top p-0 pr-1' });
	} else {
		if (showCard) headers.push({ title: t('t.chargeCardUsed'), className: 'align-top p-0 pr-1' });
		if (showLocation) headers.push({ title: t('t.chargePoint'), className: 'align-top p-0 pr-1' });
	}

	headers.push({ title: t('g.startDate'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-2 text-lg-left pr-3' });
	headers.push({ title: t('g.connector'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.startTime'), className: 'text-right align-top p-0 pr-1 col-sm-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.endTime'), className: 'text-right align-top p-0 pr-1 col-sm-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.duration'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.totalEnergy'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.invoiced'), className: 'text-right align-top p-0 pr-1 col-1' });
	{
		selectAll && headers.push({ title: 'Approve/Reject', className: 'col-1 d-print-none text-right align-top p-0 pr-1' });
	}

	return headers;
};

const groupByFunc = (arr, prop) => {
	const sortedData = _.orderBy(arr, [prop]);
	const reference = prop === 'chargeTokenDescription' ? 'chargeTokenReference' : null;

	return sortedData.reduce((acc, obj) => {
		const key = obj[prop] ?? '';
		const referenceKey = obj[reference] ?? '';
		const combinedKey = referenceKey ? `${key} | ${referenceKey}` : key;

		if (!acc[combinedKey]) {
			acc[combinedKey] = [];
		}
		acc[combinedKey].push(obj);
		return acc;
	}, {});
};

export const TransactionTable = (props) => {
	const { t } = useTranslation();
	const confirmationModalDefault = { isOpen: false, title: null, message: null, btn1Text: null, btn2Text: null, btn2Action: null, isBusy: false };
	const { transactionReimburse } = HttpContext();
	const printRef = useRef();
	const downRef = useRef();
	const selectedRecords = useRef([]);
	const [hasError, setHasError] = useState('');
	const [hasSuccess, setHasSuccess] = useState('');
	const [isBusy, setIsBusy] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState({ ...confirmationModalDefault });
	const {
		title = t('t.chargeTransactions'),
		items: data = [],
		isLoading = true,
		showCard = false,
		showLocation = false,
		swapCardLocation = false,
		showGroupHeaders = true,
		groupBy = ['chargeTokenDescription', 'transactionTimeStart'],
		loadMore = null,
		selectAll = false,
		refresh = null,
		isReimbursement = null,
		isManagePayments = false,
	} = props;

	const [items, setItems] = useState([]);

	useEffect(() => {
		if (Array.isArray(data) || data.length > 0) {
			const newItems = data.map((item) => {
				item.checked = false;
				return item;
			});

			setItems(newItems);
		}
	}, [data]);

	useEffect(() => {
		if (hasError) window.scrollTo(0, 0);
	}, [hasError]);

	// Used for display and printing
	let group1 = groupByFunc(items, groupBy[0]);
	let group2 = Object.keys(group1).map((key) => {
		const data = group1[key];
		const groupedData = groupByFunc(data, groupBy[1]);

		return { [key]: groupedData };
	});

	const headers = buildHeaders(t, showCard, showLocation, swapCardLocation, selectAll);
	const printForm = useReactToPrint({ content: () => printRef.current });

	const downloadFile = async (format = 'xlsx') => {
		const workbook = utils.book_new();
		let allData = [{ sheetName: t('t.allTranactions'), sheetData: [] }];

		GroupBy(items, groupBy).forEach((element) => {
			const data = element.data.map((row) => {
				return {
					Location: row.chargePointLocation,
					DeviceId: row.chargePointDeviceId,
					Reference: row.chargePointPhysicalReference,
					Card: `${row.chargeTokenDescription} (${row.chargeTokenIdVisibleNumber})`,
					CardReference: row.chargeTokenReference,
					Date: new Date(row.transactionTimeStart || row.transactionDateStarted),
					Time: Settings.formatDate(row.transactionTimeStart || row.transactionDateStarted, 'time'),
					Duration: Settings.formatDuration(row.transactionDuration * 1000, null, 'HH:mm:ss'),
					kWh: row.transactionEnergyUsed,
					Invoiced: Settings.formatNumber(row.transactionBilledAmountExcl || row.transactionBilledAmounts.amountExcl, 2),
				};
			});

			const firstRow = element.data[0];
			const { chargePointPhysicalReference, chargePointLocation, chargePointDeviceId } = firstRow;

			allData.push({
				sheetName: `${chargePointPhysicalReference ?? chargePointLocation}-${chargePointDeviceId}`,
				sheetData: data,
			});

			allData[0].sheetData = [...allData[0].sheetData, ...data];
		});

		allData.forEach((row, index) => {
			const worksheet = utils.json_to_sheet(row.sheetData);
			utils.book_append_sheet(workbook, worksheet, cleanSheetName(row.sheetName));

			if (index === 0 && format === 'csv') downloadCSVFile(utils.sheet_to_csv(worksheet));
		});

		if (format === 'xlsx') writeFileXLSX(workbook, `${title}.xlsx`);
	};

	const toggleCheckbox = (event, item) => {
		const isChecked = !item.checked;

		// Push/Splice transactionId onto selectedRecords variable.
		if (!isChecked) {
			const index = selectedRecords.current.findIndex((i) => i.transactionId === item.transactionId);
			if (index >= 0) selectedRecords.current.splice(index, 1);
		} else {
			selectedRecords.current.push({ status: null, transactionId: item.transactionId });
		}

		// Check/uncheck the checkbox.
		const newItems = items.map((i) => {
			if (i.transactionId === item.transactionId) {
				i.checked = !i.checked;
			}
			return i;
		});

		setItems(newItems);
	};

	const toggleAllItems = (selectAll) => {
		// Check/uncheck all checkboxs.
		const newItems = items.map((item) => {
			item.checked = selectAll;
			return item;
		});
		setItems(newItems);

		// Add all items onto selectedRecords variable.
		const selectedItems = items.map((item) => {
			return { status: null, transactionId: item.transactionId };
		});
		selectedRecords.current = selectAll ? [...selectedItems] : [];
	};

	const reimbursementTotal = () => {
		return items.reduce((accumulator, object) => {
			return object.checked ? accumulator + object.transactionBilledAmountExcl || object.transactionBilledAmounts.amountExcl : accumulator;
		}, 0);
	};

	const onReimbursement = async () => {
		setShowConfirmationModal({
			...showConfirmationModal,
			isOpen: false,
		});
		setIsBusy(true);

		try {
			let result = await transactionReimburse(selectedRecords.current);
			if (result !== null && result.data !== false) {
				selectedRecords.current = [];
				setHasSuccess(t('r.transactionsSubmittedForReimbursement'));
				refresh();
			} else {
				setHasError(`${t('r.errorReimbursingTransaction')}${selectedRecords.current.length > 1 ? 's' : ''}. ${t('r.pleaseTryAgainLater')}`);
			}
		} catch (error) {
			console.error('error: ', error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const reimbursementSelect = (e, action) => {
		if (!selectedRecords.current || selectedRecords.current.length <= 0 || !action) {
			setHasError(t('r.noTransactionsSelected'));
			return;
		}
		setHasError('');
		setHasSuccess('');

		selectedRecords.current.forEach((item) => (item.status = action));

		const actionLabel = ReimbursementActions(t).find((i) => i.value === action);
		const total = Settings.formatNumber(reimbursementTotal(), 2);
		const message = t('r.reimbursementMessage', { action: actionLabel.label, total: total });

		setShowConfirmationModal({
			isOpen: true,
			title: t('r.reimbursementConfirmation'),
			message: message,
			btn1Text: t('g.cancel'),
			btn2Text: t('r.proceed'),
			btn2Action: onReimbursement,
		});
	};

	const ShowConfirmation = () => {
		const close = () => {
			setShowConfirmationModal({ ...confirmationModalDefault });
		};

		return (
			<ConfirmModal
				isOpen={showConfirmationModal.isOpen}
				title={showConfirmationModal.title}
				btn1Text={showConfirmationModal.btn1Text}
				btn2Text={showConfirmationModal.btn2Text}
				btn1Action={close}
				btn2Action={showConfirmationModal.btn2Action}
				isBusy={isBusy}>
				{showConfirmationModal.message}
			</ConfirmModal>
		);
	};

	return (
		<div ref={printRef} className={isReimbursement ? 'pb-2' : ''}>
			<style type="text/css" media="print">
				{'@page { size: landscape; }'}
			</style>
			<NotificationAlert message={hasSuccess} margin="" />
			<ErrorAlert message={hasError} margin="" />
			<ShowConfirmation isOpen={showConfirmationModal.isOpen} />

			<CardWithHeaderFooterActions
				heading={title}
				headerActions={<HeaderActions action={{ isLoading: isLoading, hasData: group2.length > 0, printForm: printForm, downloadFile: downloadFile }} />}
				footer={t('r.reimbursements')}
				footerActions={<FooterActions action={reimbursementSelect} hasData={group2.length > 0} calculateTotal={reimbursementTotal} translate={t} />}
				hideFooter={isReimbursement}>
				{isLoading || isBusy ? (
					<Loading message={`${t('g.loading')} ${title}...`} />
				) : group2.length <= 0 ? (
					<EmptyData />
				) : (
					<Fragment>
						<div ref={downRef}>
							<GroupedTable
								headers={headers}
								items={group2}
								rowTemplate={(item, index, rest) => tableRow(item, index, rest, selectAll, toggleCheckbox)}
								showGroupHeaders={showGroupHeaders}
								groupTemplate={groupBy === null ? null : groupTemplate}
								footTemplate={tableFooter}
								showCard={showCard}
								showLocation={showLocation}
								swapCardLocation={swapCardLocation}
								selectAll={selectAll && toggleAllItems}
								isManagePayments={isManagePayments}
								t={t}
							/>
						</div>

						{loadMore && (
							<div className="d-flex justify-content-center">
								<button className="btn btn-link text-gray-500 text-xs" onClick={loadMore}>
									{t('g.loadMore')}
								</button>
							</div>
						)}
					</Fragment>
				)}
			</CardWithHeaderFooterActions>
		</div>
	);
};
