import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { FilterInitial } from './components/FilterInitial';

import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { FilterDate } from '../../components/filters/FilterDate';
import { PagedListContext } from '../../dataAccess/PagedListContext';
import { FilterRecords } from '../../components/filters/FilterRecords';
import { FilterHelper } from '../../utils/FilterHelper';
import { FilterCardOptions } from './components/FilterCardOptions';
import { FilterChargePointOptions } from '../../components/filters/FilterChargePointOptions';
import { FilterReimbursementOptions } from './components/FilterReimbursementOptions';
import { ReimbursementTable } from './components/ReimbursementTable';

import { TransactionTable } from './components/TransactionTable';

const ReportType = {
	approval: 'approvals',
	managePayment: 'managePayments',
};

const mapData = (items = [], filter = null) => {
	const locations = [
		...items.map((element) => {
			return { recId: element.locationId, identifier: element.parentName, checked: false, description: element.locationName };
		}),
	].filter((element, index, self) => index === self.findIndex((x) => x.recId === element.recId));

	const chargePoints = [
		...items.map((element) => {
			return { locationId: element.locationId, recId: element.recId, identifier: element.identity, checked: false, description: element.physicalReference ?? element.identity ?? '<not set>' };
		}),
	].filter((element) => FilterHelper.filterItem(element, FilterHelper.getFilterItemId(filter)));

	return { locations, chargePoints };
};

export const FinanceFilter = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [filter, setFilter] = useState([]);
	const [stepSize, setStepSize] = useState(1);
	const [optIndex, setOptIndex] = useState(0);
	const [reportType, setReportType] = useState('');
	const [swapCardLocation, setSwapCardLocation] = useState(false);
	const [isReimbursements, setIsReimbursements] = useState(false);
	const paramString = useRef();

	// Selection Data
	const { data: locationData, getData: getLocationData } = PagedListContext('chargePoint', null, 10000);
	const { data: cardData, getData: getCards } = PagedListContext('chargeTag', null, 10000);
	const { data: rangedData, getData: getRangedData } = PagedListContext(`chargeTransaction`, null, 10000);
	const { data: statementData, getData: getStatementData } = PagedListContext(`chargeTransactionStatement`, null, 10000);

	const { locations, chargePoints } = mapData(locationData.items, filter.at(3) ?? []);

	const chargeCards = cardData.items?.map((element) => {
		return { recId: element.recId, description: element.description, checked: false, identifier: element.visibleNumber };
	});

	const isManagePayments = filter[1]?.dataType === ReportType.managePayment;

	const updateFilter = async (filterItem) => {
		filter.push(filterItem);
		await navForward(filterItem);
	};

	const buildEntityFilterParams = (selectedItems) => {
		let queryParams = '';

		if (selectedItems !== null && selectedItems !== undefined && selectedItems.length > 0) {
			const groupedSelectedItems = _.groupBy(selectedItems, (e) => e.dataType);

			for (const groupKey in groupedSelectedItems) {
				const grouping = groupedSelectedItems[groupKey];

				const groupedIds = grouping.map((e) => e.entityId);
				const selectedItemString = groupedIds.join(',');

				switch (groupKey.toLowerCase()) {
					case 'locations':
						queryParams += groupedIds.length === 1 ? `&locationId=${selectedItemString}` : `&filterType=locations&keys=${selectedItemString}`;
						break;
					case 'chargepoints':
						queryParams += groupedIds.length === 1 ? `&chargePointId=${selectedItemString}` : `&filterType=chargepoints&keys=${selectedItemString}`;
						break;
					case 'users':
						queryParams += groupedIds.length === 1 ? `&userId=${selectedItemString}` : `&filterType=users&keys=${selectedItemString}`;
						break;
					default:
						queryParams += groupedIds.length === 1 ? `&chargeTagId=${selectedItemString}` : `&filterType=chargecards&keys=${selectedItemString}`;
						break;
				}
			}
		}

		return queryParams;
	};

	const buildQueryParams = (reportType, selectedItems, dateStart, dateEnd) => {
		let defaultParams = `startDate=${dateStart}&endDate=${dateEnd}${buildEntityFilterParams(selectedItems)}`;

		switch (reportType.toLowerCase()) {
			case ReportType.approval:
				return `${defaultParams}&statementStatuses=Pending`;
			case ReportType.managePayment:
				return `${defaultParams}&statuses=Settled,Approved,Receipted,AwaitingPayment`;
			default:
				return defaultParams;
		}
	};

	const getFilterPageSelectedItems = () => {
		let selectedItems = [];

		let prevDataType = '';
		filter.forEach((element) => {
			prevDataType = element.dataType !== 'selection' ? element.dataType : prevDataType;

			if (element.multiSelect === false) {
				selectedItems.push({ dataType: prevDataType, entityId: element.selection[0].recId });
			} else if (element.dataType === 'selection') {
				const newSelectedItems = element.selection.map((element) => {
					return { dataType: prevDataType, entityId: element.recId };
				});
				selectedItems = [...selectedItems, ...newSelectedItems];
			}
		});

		return selectedItems;
	};

	const navForward = async (filterItem) => {
		const nextIndex = optIndex + filterItem.stepSize;
		setStepSize(stepSize);
		setOptIndex(nextIndex);

		// Last step.
		if (nextIndex === 8 || nextIndex === 9) {
			const { dataType = '', dateStart, dateEnd } = filter.at(-1) ?? {};
			if (dataType === 'dates') {
				const { dataType: reportType = '' } = filter.at(1) ?? {};

				setSwapCardLocation(reportType === 'locations');
				setReportType(reportType);
				setIsReimbursements(reportType === ReportType.approval);

				let selectedItems = getFilterPageSelectedItems();

				paramString.current = buildQueryParams(reportType, selectedItems, dateStart, dateEnd);

				// Fetch data for Manage Payments.
				if (nextIndex === 8) await getStatementData('', '', paramString.current, true);
				// Fetch data for other tables.
				if (nextIndex === 9) await getRangedData('', '', paramString.current, true);
			}
		}
	};

	const navBackward = () => {
		const filterItem = filter.at(-1) ?? { stepSize: 1 };
		const prevIndex = optIndex - filterItem.stepSize;

		filter.pop();
		setFilter(filter);

		if (prevIndex < 0) navigate(-1);
		else setOptIndex(prevIndex);
	};

	const refresh = async () => {
		await getRangedData('', '', paramString.current, true);
	};

	useEffect(() => {
		getLocationData();
		getCards();
	}, []);

	const items = isManagePayments && optIndex === 9 ? statementData.items[filter.slice(-1)[0]?.dataIndex]?.transactions : _.orderBy(rangedData.items, 'transactionTimeStart', 'desc');
	const isBusy = (isManagePayments && optIndex === 8) || (isManagePayments && optIndex === 9) ? statementData.isBusy : rangedData.isBusy;

	const stepSet = () => {
		return isManagePayments ? 1 : 2;
	};

	return (
		<Page>
			<BreadCrumb title={t('f.finance')} filter={filter} navBackward={navBackward} />

			{optIndex === 0 && <FilterInitial setFilter={updateFilter} />}
			{optIndex === 1 && <FilterReimbursementOptions setFilter={updateFilter} />}
			{optIndex === 2 && <FilterCardOptions setFilter={updateFilter} category={filter[0].dataType} />}
			{optIndex === 3 && <FilterRecords heading={t('t.cards')} setFilter={updateFilter} stepSize={4} items={chargeCards} isLoading={cardData.isBusy} />}
			{optIndex === 4 && <FilterRecords heading={t('t.locations')} setFilter={updateFilter} stepSize={1} multiSelect={false} items={locations} isLoading={locationData.isBusy} />}
			{optIndex === 5 && <FilterChargePointOptions setFilter={updateFilter} allChargePointsStep={2} />}
			{optIndex === 6 && <FilterRecords heading={t('cp.chargePoints')} setFilter={updateFilter} stepSize={1} multiSelect={true} items={chargePoints} />}
			{optIndex === 7 && <FilterDate setFilter={updateFilter} stepSize={stepSet()} />}
			{optIndex === 8 && <ReimbursementTable filter={filter} items={statementData.items} isLoading={isBusy} setFilter={updateFilter} />}
			{optIndex === 9 && (
				<TransactionTable
					filter={filter}
					items={!rangedData.hasError ? items : []}
					isLoading={isBusy}
					swapCardLocation={swapCardLocation}
					showLocation={reportType === 'chargeCards'}
					showCard={reportType === 'locations'}
					groupBy={swapCardLocation !== true ? ['chargeTokenDescription', 'chargePointPhysicalReference'] : ['chargePointLocation', 'chargePointPhysicalReference']}
					selectAll={isReimbursements}
					refresh={refresh}
					isReimbursement={isReimbursements}
					isManagePayments={isManagePayments}
				/>
			)}
		</Page>
	);
};
